import React from 'react'

const Footer = () => {
  return (
   <footer className='bg-slate-200'>
    <div className='container mx-auto p-4'>
      <div className='flex items-center justify-center gap-4'>
         <p className='text-center  font-sm-bold'>All Copyreserved - 2024</p>
      <p className='text-center font-sm-bold '>PashuPrani.com</p>
      </div>
     
    </div>
   </footer>
  )
}

export default Footer