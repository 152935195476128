import React from 'react';
import PropTypes from 'prop-types';

const AboutUs = (props) => {
  return (
    <div className="about-us-container font-sans min-h-screen flex flex-col">
      <div className="top-header-right"></div>
      <div className="contact-list">
        <div className="hero-section bg-blue-500 text-white py-20">
          <div className="container mx-auto text-center">
            <h4 className="text-4xl font-bold mb-4">
              We Need Your Help <br /> <span className="text-yellow-400">Adopt Us</span>
            </h4>
            <p className="text-lg mb-6">
              We are committed to rescuing and rehabilitating Indian breed animals. Your support makes it possible for us to provide these animals with a second chance at life, offering them the love and care they deserve.
            </p>
            <button className="bg-yellow-500 hover:bg-yellow-400 text-black font-bold py-2 px-6 rounded-lg transition duration-300">
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

AboutUs.propTypes = {};

export default AboutUs;
