import React, { useState } from "react";
import { FaRegUser } from "react-icons/fa6";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import imageTobase64 from "../helpers/imagestobase64";
import summaryApi from "../common";
import { toast } from "react-toastify";

const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
    confirmpassword: "",
    name: "",
    phone: "",
    profilePic: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (data.password !== data.confirmpassword) {
      toast.error("Passwords do not match!");
      return;
    }

    try {
      const response = await fetch(summaryApi.signUp.url, {
        method: summaryApi.signUp.method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const dataApi = await response.json();

      if (dataApi.success) {
        toast.success(dataApi.message);
        navigate("/login");
      } else if (dataApi.error) {
        toast.error(dataApi.message);
      }
    } catch (error) {
      toast.error("Failed to register. Please try again later.");
    }
  };

  const handleUploadPic = async (e) => {
    const file = e.target.files[0];
    const imagePic = await imageTobase64(file);
    setData((prev) => ({
      ...prev,
      profilePic: imagePic,
    }));
  };

  return (
    <section id="signup">
      <div className="mx-auto container px-4 p-5">
        <div className="bg-blue-100 p-5 w-full max-w-sm mx-auto">
          <div className="w-20 h-20 mx-auto flex justify-center flex-col relative overflow-hidden rounded-full">
            <FaRegUser className="text-3xl h-[20px] w-[80px] opacity-35" />
            {data.profilePic && <img src={data.profilePic} alt="User profile picture" />}
            <form>
              <label>
                <div className="text-sm bg-opacity-90 bg-slate-200 pb-1 cursor-pointer hover:text-blue-600 text-center absolute w-full bottom-0">
                  Upload Photo
                </div>
                <input type="file" className="hidden" onChange={handleUploadPic} />
              </label>
            </form>
          </div>

          <form className="pt-7 flex flex-col gap-2" onSubmit={handleSubmit}>
            <div className="grid">
              <label>Name</label>
              <div className="bg-slate-200 p-2">
                <input
                  type="text"
                  placeholder="Enter your full name"
                  name="name"
                  value={data.name}
                  required
                  onChange={handleChange}
                  className="w-full outline-none bg-transparent"
                />
              </div>
            </div>
            <div className="grid">
              <label>Phone</label>
              <div className="bg-slate-200 p-2">
                <input
                  type="number"
                  placeholder="Enter your phone number"
                  name="phone"
                  value={data.phone}
                  required
                  onChange={handleChange}
                  className="w-full outline-none bg-transparent"
                />
              </div>
            </div>
            <div className="grid">
              <label>Email</label>
              <div className="bg-slate-200 p-2">
                <input
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={data.email}
                  required
                  onChange={handleChange}
                  className="w-full outline-none bg-transparent"
                />
              </div>
            </div>
            <div>
              <label>Password</label>
              <div className="bg-slate-200 p-2 flex">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your password"
                  name="password"
                  value={data.password}
                  required
                  onChange={handleChange}
                  className="w-full outline-none bg-transparent"
                />
                <div
                  className="cursor-pointer text-lg"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
            </div>
            <div>
              <label>Confirm Password</label>
              <div className="bg-slate-200 p-2 flex">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm your password"
                  name="confirmpassword"
                  value={data.confirmpassword}
                  required
                  onChange={handleChange}
                  className="w-full outline-none bg-transparent"
                />
                <div
                  className="cursor-pointer text-lg"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
            </div>
            <button className="bg-green-500 hover:bg-blue-600 text-white px-5 py-2 w-full max-w-[200px] rounded-full hover:scale-105 transition-all mx-auto mt-6">
              Register
            </button>
          </form>

          <p className="p-5 ml-10">
            Already have an account?{" "}
            <Link to="/login" className="text-green-900 hover:text-blue-400 hover:underline">
              Login
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Signup;
