import React from 'react';
import Logo from "../assets/png/logo.png";
import { BiSearch } from "react-icons/bi";
import { FaUserAlt } from "react-icons/fa";
import { FaCartFlatbedSuitcase } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie'; // Import js-cookie

const Header = () => {
  // Retrieve the username from cookies
  const username = Cookies.get('username');

  return (
    <>
      <header className='h-20 shadow-md bg-white'>
        <div className='h-full flex container mx-auto px-4 justify-between items-center'>
          <Link to={"/"}>
            <img src={Logo} width={80} height={30} alt="Logo" />
          </Link>

          {/* Search bar component */}
          <div className='hidden lg:flex items-center w-full justify-between max-w-sm border rounded-full focus-within:shadow pl-2'>
            <input type='text' placeholder='Enter Animal Breed..' className='w-full outline-none' />
            <div className='text-lg min-w-[50px] bg-blue-500 h-8 flex items-center justify-center rounded-r-full cursor-pointer hover:bg-green-600'>
              <BiSearch />
            </div>
          </div>

          <div className='flex gap-7 items-center'>
            <div className='text-3xl cursor-pointer'>
              <FaUserAlt />
            </div>
            <div className='text-3xl relative'>
              <span>
                <FaCartFlatbedSuitcase />
              </span>
              <div className='bg-red-600 text-white w-5 h-5 rounded-full p-1 flex items-center justify-center absolute -top-2 -right-3'>
                <p className='text-sm'>0</p>
              </div>
            </div>

            {/* Conditional Rendering for Login Button or Username */}
            {username ? (
              <span className='px-3 py-1 rounded-full text-blue-600 text-xl'>
                Welcome, {username}!
              </span>
            ) : (
              <Link to={"/login"} className='px-3 py-1 rounded-full text-white text-xl hover:bg-green-600 hover:text-blue-900 bg-blue-600'>
                Login
              </Link>
            )}
          </div>
        </div>
      </header>

      {/* Contact Information Header */}
      <header className="bg-gray-800 text-white shadow-lg ">
        <div className="flex justify-between items-center p-4">
          <div className="text-right">
            <ul className="flex space-x-4">
              <li className='hover:text-green-900'>
                <a
                  href="https://wa.me/918975205927"
                  className=" transition duration-300 hover:text-green-900 hover:bg-white hover:rounded-full hover:p-1"
                  target="_blank"
                  rel="noopener noreferrer"
                  
                >
                  8975205927
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-gray-400 transition duration-300">Mon - Sat 10:00 - 7:00pm</a>
              </li>
            </ul>
          </div>
        </div>
      </header>

      {/* Top Navbar for Medium and Larger Screens */}
      <nav className="hidden md:flex bg-gray-800 text-white justify-around p-4">
        <Link to="/" className="hover:text-gray-400 transition duration-300">Home</Link>
        <Link to="/aboutus" className="hover:text-gray-400 transition duration-300">About Us</Link>
        <Link to="/gallery" className="hover:text-gray-400 transition duration-300">Gallery</Link>
        <Link to="/adoption-form" className="hover:text-gray-400 transition duration-300">Adoption Form</Link>
        <Link to="/contact" className="hover:text-gray-400 transition duration-300">Contact Us</Link>
      </nav>
    </>
  );
}

export default Header;
