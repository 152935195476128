import React, { useState } from "react";
import Logo from "../assets/png/logo.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [isLoggedIn, setIsLoggedIn] = useState(false); // New state to track login status
  const [username, setUsername] = useState(""); // New state for username
  const navigate = useNavigate();

  const handleOneChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("http://localhost:3001/api/v1/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (result.success) {
        toast.success(result.message);

        // Store token in local storage or cookies if using JWT
        localStorage.setItem("token", result.token);

        // Extract username from email
        const extractedUsername = data.email.split("@")[0]; // Get part before "@" as username
        setUsername(extractedUsername);
        setIsLoggedIn(true); // Update login status

        // Redirect to home page after successful login
        navigate("/");
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error("An error occurred while logging in.");
    }
  };

  return (
    <section id="login">
      <div className="mx-auto container px-4 p-5">
        <div className="bg-blue-100 px-4 py-5 w-full max-w-sm mx-auto rounded-sm">
          <div className="w-20 h-20 mx-auto ">
            <img src={Logo} width={80} height={30} alt="Logoicon" />
          </div>
          <form className="pt-6 flex flex-col gap-2" onSubmit={handleSubmit}>
            <div className="grid">
              <label>Phone / Email</label>
              <div className="bg-slate-200 p-2 ">
                <input
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={data.email}
                  onChange={handleOneChange}
                  className="w-full h-full outline-none bg-transparent"
                />
              </div>
            </div>
            <div>
              <label>Password</label>
              <div className="bg-slate-200 p-2 flex">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your password"
                  name="password"
                  value={data.password}
                  onChange={handleOneChange}
                  className="w-full h-full outline-none bg-transparent"
                />
                <div
                  className="cursor-pointer text-lg"
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  <span>{showPassword ? <FaEyeSlash /> : <FaEye />}</span>
                </div>
              </div>
              <Link
                to="/forget"
                className="block w-fit ml-auto hover:underline hover:text-green-600"
              >
                Forgot Password?
              </Link>
            </div>
            {!isLoggedIn ? ( // Conditional rendering based on login status
              <button className="bg-green-500 hover:bg-blue-600 hover:text-blue-100 text-white px-5 py-2 w-full max-w-[200px] rounded-full hover:scale-105 transition-all mx-auto block mt-6">
                Login
              </button>
            ) : (
              <div className="text-green-900 text-center mt-6">
                Welcome, {username}! {/* Display username */}
              </div>
            )}
          </form>
          <p className="my-2">
            Don't have an account?{" "}
            <Link
              to="/signup"
              className="text-green-900 hover:text-blue-400 hover:text-1px hover:underline"
            >
              Sign-up
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Login;
